/* mobile first design */

/* brand font */
@import url('https://fonts.googleapis.com/css2?family=Zeyada&display=swap');

nav {
  color: #00A8E8;
  font-size: .8rem;
  background: RGB(62, 73, 91);
}

nav a {
  text-decoration: none;
  /* -webkit-text-decoration is necessary for safari */
  -webkit-text-decoration: none;
  color: #00A8E8;
  letter-spacing: 2px;
}

nav a:visited {
  color: #00A8E8;
  text-decoration: none;
  -webkit-text-decoration: none;
}

nav a:hover {
  text-decoration: none;
  -webkit-text-decoration: none;
  color: white;
}

nav a.active {
  border-bottom: 2.5px dotted white;
}

nav a.active, a:hover {
  color: #00A8E8;
}

.navbar-brand {
  font-family: 'Zeyada', cursive;
  font-size: 2.2em;
  padding-bottom: 0%;
}

.navbar-brand:hover {
  color: white;
}

/* Updates for larger devices */

@media screen and (min-width: 768px) {
  nav {
    font-size: 1.1rem;
  }

  .navbar-brand {
    font-size: 2.5em;
  }

  /* add margin when navbar links expand (md size screen and larger) */
  .md-margin {
    margin-right: calc(1rem * 1.5);
  }
}

@media screen and (min-width: 1680px) {
  nav {
    font-size: 1.3rem;
    max-height: 90px;
  }
}

