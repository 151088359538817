.Footer {
  color: white;
}

.icon {
  width: 32px;
  height: 32px;
}

.Footer a {
  color: white;
}

.Footer a:hover {
  text-decoration: none;
  color: #00A8E8;
}

.Footer svg:hover {
  fill: #00A8E8;
}