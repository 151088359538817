/* mobile first design */

/* signature font */
@import url('https://fonts.googleapis.com/css2?family=Zeyada&display=swap');

.About {
  text-align: center;
  height: 100vh;
  width: 80%;
  margin: auto;
  font-size: .95rem;
  letter-spacing: .5px;
}

.About-title-container {
  text-align: left;
  padding-top: 3%;
}

.About-title {
  color: RGB(242, 112, 89);
  font-weight: bold;
  padding-top: 3%;
  padding-bottom: 2%;
  letter-spacing: 2px;
  font-size: 1.1rem;
}

.headshot-container {
  max-height: 150px;
  max-width: 150px;
  display: inline-block;
  position: relative;
  margin-right: 5%;
}

.headshot {
  border: solid white;
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.About-content-container {
  padding-top: 3%;
}

.About-signature {
  font-family: 'Zeyada', cursive;
  color: #00A8E8;
  font-size: 2.1rem;
  padding-bottom: 15%;
  padding-top: 5%;
  transform: rotate(-6deg);
}

/* Updates for larger devices */

@media screen and (min-width:600px) {
  .About {
    width: 70%;
  }
}

@media screen and (min-width: 768px) {
  .About {
    font-size: 1.1rem;
    width: 50%;
  }

  .headshot-container {
    max-width: 225px;
    max-height: 225px;
  }

  .About-title {
    font-size: 1.5rem;
  }

  .About-title-container {
    display: inline-block;
    margin-right: 5%;
  }
  .About-signature {
    font-size: 2.8rem;
    padding-bottom: 10%;
  }
}

@media screen and (min-width: 1680px) {
  .About {
    font-size: calc(.5vw + .5vh + .5vmin);
  }
  .About-signature {
    font-size: 3.3rem;
    padding-bottom: 5%;
  }
}